import axios from "@/libs/axios";

let base = '';
export const login = (param) => {
    return axios.request({
        url: `${base}/api/user/login`,
        params: param,
        method: 'get'
    });
}
export const logout = () => {
    return axios.request({
        url: `${base}/api/user/logout`,
        method: 'get'
    });
}
export const getCurrentUser = () => {
    return axios.request({
        url: `${base}/api/user/current`,
        method: 'get'
    });
}
export const modifyPwd = (param) => {
    return axios.request({
        url: `${base}/api/user/modify/pwd`,
        method: 'post',
        params: param
    });
}
export const getUserList = (param) => {
    return axios.request({
        url: `${base}/api/user/list`,
        method: 'post',
        data: param
    });
}
export const getUserDetail = (id) => {
    return axios.request({
        url: `${base}/api/user/` + id,
        method: 'get'
    });
}
export const saveUser = (param) => {
    return axios.request({
        url: `${base}/api/user/save`,
        method: 'post',
        data: param
    });
}
export const deleteUser = (id) => {
    return axios.request({
        url: `${base}/api/user/del/` + id,
        method: 'get'
    });
}
export const getCompanyGrantList = (param) => {
    return axios.request({
        url: `${base}/api/user/grant/tree`,
        method: 'get',
        params: param
    });
}
export const grantCompany = (param) => {
    return axios.request({
        url: `${base}/api/user/grant/`,
        method: 'post',
        data: param
    });
}
export const syncCorpWxUsers = () => {
    return axios.request({
        url: `${base}/api/user/sync/corpwx/users`,
        method: 'get'
    });
}


// 角色管理
export const getRoleList = (param) => {
    return axios.request({
        url: `${base}/api/role/list`,
        method: 'post',
        data: param
    });
}
export const getRoleDetail = (id) => {
    return axios.request({
        url: `${base}/api/role/` + id,
        method: 'get'
    });
}
export const saveRole = (param) => {
    return axios.request({
        url: `${base}/api/role/save`,
        method: 'post',
        data: param
    });
}
export const deleteRole = (id) => {
    return axios.request({
        url: `${base}/api/role/del/` + id,
        method: 'get'
    });
}
export const getRolePermissions = (param) => {
    return axios.request({
        url: `${base}/api/role/permission/list`,
        method: 'get',
        params: param
    });
}
export const getPermissionList = (param) => {
    return axios.request({
        url: `${base}/api/apiResource/list`,
        method: 'post',
        data: param
    });
}
export const getPermissionModalList = () => {
    return axios.request({
        url: `${base}/api/apiResource/modal/list`,
        method: 'get'
    });
}
export const grantRolePermission = (param) => {
    return axios.request({
        url: `${base}/api/role/permission/grant`,
        method: 'post',
        data: param
    });
}

// 地区管理
export const getAreaTree = () => {
    return axios.request({
        url: `${base}/api/area/tree`,
        method: 'get'
    });
}
export const getAreaDetail = (id) => {
    return axios.request({
        url: `${base}/api/area/` + id,
        method: 'get'
    });
}
export const getAreaPrevs = (param) => {
    return axios.request({
        url: `${base}/api/area/prevs`,
        method: 'get',
        params: param
    });
}
export const saveArea = (param) => {
    return axios.request({
        url: `${base}/api/area/save`,
        method: 'post',
        data: param
    });
}
export const deleteArea = (id) => {
    return axios.request({
        url: `${base}/api/area/del/` + id,
        method: 'get'
    });
}

// 日志管理
export const getLogList = (param) => {
    return axios.request({
        url: `${base}/api/log/list`,
        method: 'post',
        data: param
    });
}
export const getLogDetail = (id) => {
    return axios.request({
        url: `${base}/api/log/` + id,
        method: 'get'
    });
}

// 部门管理
export const getDepartmentTree = (param) => {
    return axios.request({
        url: `${base}/api/department/tree`,
        method: 'get',
        params: param
    });
}
export const getDepartmentList = (param) => {
    return axios.request({
        url: `${base}/api/department/list`,
        method: 'get',
        params: param
    });
}
export const getDepartmentDetail = (id) => {
    return axios.request({
        url: `${base}/api/department/` + id,
        method: 'get'
    });
}
export const getDepartmentPrevs = (param) => {
    return axios.request({
        url: `${base}/api/department/prevs`,
        method: 'get',
        params: param
    });
}
export const saveDepartment = (param) => {
    return axios.request({
        url: `${base}/api/department/save`,
        method: 'post',
        data: param
    });
}
export const deleteDepartment = (id) => {
    return axios.request({
        url: `${base}/api/department/del/` + id,
        method: 'get'
    });
}
export const deleteDepartmentBatch = (id) => {
    return axios.request({
        url: `${base}/api/department/del/` + id,
        method: 'get'
    });
}

// 系统设置
export const getSettingDetail = (id) => {
    return axios.request({
        url: `${base}/api/setting/` + id,
        method: 'get'
    });
}
export const saveSetting = (param) => {
    return axios.request({
        url: `${base}/api/setting/save`,
        method: 'post',
        data: param
    });
}