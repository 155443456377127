import main from '@/views/main.vue';

/**
 * 限定meta为必填
 * - title表示标题
 * - hideInMenu表示是否在菜单上显示，true隐藏， false显示，可为空
 * - icon表示菜单图标，可为空
 * - access: (null) 可访问该页面的权限数组，当前路由设置的权限会影响子路由
 */
export default [{
    path: '/login',
    name: 'login',
    meta: {
        title: '登录',
        hideInMenu: true
    },
    component: () => import('@/views/login.vue')
}, {
    path: '/',
    name: '_home',
    redirect: '/home',
    meta: {
        title: '首页',
        hideInMenu: true
    },
    component: main,
    children: [{
        path: '/home',
        name: 'home',
        meta: {
            title: '首页',
            icon: 'md-home',
            hideInMenu: true
        },
        component: () => import('@/views/home.vue')
    }, {
        path: '/personal',
        name: 'personal',
        meta: {
            title: '个人资料',
            hideInMenu: true
        },
        component: () => import('@/views/personal/person.vue')
    }, {
        path: '/changePass',
        name: 'changePass',
        meta: {
            title: '修改密码',
            hideInMenu: true
        },
        component: () => import('@/views/personal/modifyPass.vue')
    }]
}, {
	path: '/system',
	name: 'system',
	meta: {
		title: '系统管理',
		icon: 'md-settings'
	},
	component: main,
	children: [{
	// 	path: '/system/manageUser',
	// 	name: 'manageUserManage',
	// 	meta: {
	// 		title: '管理员管理',
	// 		icon: 'md-people',
	// 		parent: 'system',
	// 		access: [ 'super' ]
	// 	},
	// 	component: () => import('@/views/system/manageUser.vue')
	// }, {
		path: '/system/role',
		name: 'roleManage',
		meta: {
			title: '角色管理',
			icon: 'md-person',
			parent: 'system'
		},
		component: () => import('@/views/system/role.vue')
	}, {
		path: '/system/department',
		name: 'departmentManage',
		meta: {
			title: '部门管理',
			icon: 'md-person',
			parent: 'system'
		},
		component: () => import('@/views/system/department.vue')
	}, {
		path: '/system/area',
		name: 'areaManage',
		meta: {
			title: '行政区划',
			icon: 'md-person',
			parent: 'system'
		},
		component: () => import('@/views/system/area.vue')
	}, {
		path: '/system/log',
		name: 'logManage',
		meta: {
			title: '日志管理',
			icon: 'md-person',
			parent: 'system'
		},
		component: () => import('@/views/system/log.vue')
	}, {
		path: '/system/setting',
		name: 'settingManage',
		meta: {
			title: '系统设置',
			icon: 'md-person',
			parent: 'system'
		},
		component: () => import('@/views/system/setting.vue')
	}]
}, {
	path: '/company',
	name: 'company',
	meta: {
		title: '企业管理',
		icon: 'ios-book'
	},
	component: main,
	children: [{
		path: '/company/distributor',
		name: 'distributorManage',
		meta: {
			title: '经销商管理',
			icon: 'md-git-branch',
			parent: 'company'
		},
		component: () => import('@/views/company/distributor.vue')
	}, {
		path: '/company/factory',
		name: 'factoryManage',
		meta: {
			title: '厂方管理',
			icon: 'md-git-branch',
			parent: 'company'
		},
		component: () => import('@/views/company/factory.vue')
	}, {
		path: '/company/agency',
		name: 'agencyManage',
		meta: {
			title: '第三方机构管理',
			icon: 'md-git-branch',
			parent: 'company'
		},
		component: () => import('@/views/company/agency.vue')
	}]
}, {
	path: '/complaint',
	name: 'complaint',
	meta: {
		title: '投诉管理',
		icon: 'ios-book'
	},
	component: main,
	children: [{
		path: '/complaint/complaint',
		name: 'complaintManage',
		meta: {
			title: '投诉单管理',
			icon: 'md-git-branch',
			parent: 'complaint'
		},
		component: () => import('@/views/complaint/complaint.vue')
	}, {
		path: '/complaint/complaintDetail',
		name: 'complaintDetailManage',
		meta: {
			title: '投诉单详情',
			icon: 'md-git-branch',
			hideInMenu: true,
			parent: 'complaint'
		},
		component: () => import('@/views/complaint/complaintDetail.vue')
	}]
}, {
	path: '/user',
	name: 'user',
	meta: {
		title: '用户管理',
		icon: 'ios-book'
	},
	component: main,
	children: [{
		path: '/user/user',
		name: 'userManage',
		meta: {
			title: '用户管理',
			icon: 'md-git-branch',
			parent: 'user'
		},
		component: () => import('@/views/user/user.vue')
	}]
}, {
	path: '/stock',
	name: 'stock',
	component: main,
	meta: {
		title: '出入库管理',
		icon: 'md-folder'
	},
	children: [{
		path: '/stock/in',
		name: 'stockInManage',
		meta: {
			title: '入库管理',
			icon: 'md-document',
			parent: 'stock'
		},
		component: () => import('@/views/stock/stockIn.vue')
	}, {
		path: '/stock/out',
		name: 'stockOutManage',
		meta: {
			title: '出库管理',
			icon: 'md-document',
			parent: 'stock'
		},
		component: () => import('@/views/stock/stockOut.vue')
	}, {
		path: '/stock/store',
		name: 'storeManage',
		meta: {
			title: '库存管理',
			icon: 'md-document',
			parent: 'stock'
		},
		component: () => import('@/views/stock/store.vue')
	}]
}, {
	path: '/401',
	name: 'error_401',
	meta: {
		hideInMenu: true
	},
	component: () => import('@/views/error-page/401.vue')
}, {
	path: '/500',
	name: 'error_500',
	meta: {
		hideInMenu: true
	},
	component: () => import('@/views/error-page/500.vue')
}, {
	path: '/:catchAll(.*)',
	name: 'error_404',
	meta: {
		hideInMenu: true
	},
	component: () => import('@/views/error-page/404.vue')
}];