import axios from "axios";
import router from '@/router';
import {Message} from 'view-ui-plus';

// 超时设定
axios.defaults.timeout = 150000;
axios.interceptors.request.use(config => {
    const _sessionKey = sessionStorage.getItem('sessionKey');
    if (_sessionKey) {
      config.headers['Connect-Sids'] = _sessionKey;
    }
    // config.url = '/cross' + config.url
    return config;
}, err => {
    Message.error('请求超时');
    return Promise.resolve(err);
});

// http response 拦截器
axios.interceptors.response.use(response => {
    // 根据返回的code值来做不同的处理(和后端约定)
    switch (response.data.code) {
        case 400:
            // 未登录 清除已登录状态
            sessionStorage.clear();
            Message.error('您未登录或登录超时，请重新登录');
            router.push({path:'/login'});
            break;
        case 401:
            // 没有权限
            Message.error(response.data.msg);
            break;
        default:
            return response.data;
    }
    return response;
}, (err) => {
    // 返回状态码不为200时候的错误处理
    // Message.error(err.toString());
    return Promise.resolve(err);
});

export default axios;